﻿/**
 *  @ngdoc Constant
 *  @description Constant for enum
 */
(function () {
    "use:strict";

    //var myApp = angular.module('myApp', []);
    angular.module("myApp").constant('Constants', {
        autocompleteType: {
            practiceName: 'practiceName',
            accountNo: 'accountNo',
            orderinvoice: 'orderInvoice',
            order: 'order',
            rxdxorders: 'rxdxorders',
            dfsSetId: 'dfsSetId',
            firstName: 'firstName',
            lastName: 'lastName',
            patient: 'patient',
            patientByAcct: 'patientByAcct',
            zipcode: 'zipcode',
            users: 'users',
            salesRepUsers: 'salesRepUsers',
            usersWithFname: 'usersWithFname',
            adminVoUsers: 'adminVoUsers',
            email: 'email'
        },
        orderLensType: {
            new: 1,
            remake: 2,
            modification: 3,
            spare: 4,
            duplicate: 5,
            reOrder: 6
        },
        orderStatus: {
            placed: 1,
            manufacturing: 2,
            shipping: 3,
            shipped: 4,
            returned: 5,
            finalInspection: 6,
            saved: 7,
            cancelled: 8
        },
        eye: {
            OD: 1,
            OS: 2,
            OU: 3
        },
        lensCategory: {
            corneal: 1,
            scleral: 2
        },
        orderType: {
            RX: 1,
            DX: 2,
            AX: 3,
            VV: 4
        },
        machineType: {
            FP: 1,
            LDS: 2,
            LAT: 3
        }
    });


}(window.angular));
